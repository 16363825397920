import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'

const Hero = () => {
    return (
        <StaticQuery 
            query={graphql`
                {
                    allDataJson {
                        edges {
                            node {
                                homeMessage
                                homeImage
                            }
                        }
                    }
                }
            `}
            render={data => (
                <section
                    className="hero is-bold is-primary is-fullheight"
                    style={{
                        backgroundImage: ("url(" + data.allDataJson.edges[0].node.homeImage + ")"),
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                    }}
                >
                    <div className="hero-head">
                        <Header />
                    </div>

                    <div className="hero-body">
                        <div className="container">
                            <h1 className="title">
                                {data.allDataJson.edges[0].node.homeMessage}
                            </h1>
                        </div>
                    </div>
                </section>
            )}
        />
    )
}

export default Hero