import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'

const IndexPage = () => (
    <>
        <Seo title="Home" />
        <Layout noHeader>
            <Hero />
        </Layout>
    </>
)

export default IndexPage
